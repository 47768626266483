import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
    Select,
    MenuItem,
} from '@mui/material';

export const UserOrganization = ({
    organization,
    organizations,
    disabled,
    setAccess,
}) => {
    const { t } = useTranslation();
    const handleSelectOrganization = event => {
        setAccess({ organizationId: event.target.value });
    };

    if (!organizations.length) return null;

    return (
        <FormControl fullWidth sx={{ m: 1 }} variant='standard'>
            <InputLabel htmlFor='component-simple'>
                {t('organization')}
            </InputLabel>
            <Select
                id='component-simple'
                labelId='demo-simple-select-helper-label'
                label={t('organization')}
                value={organization}
                onChange={handleSelectOrganization}
                input={<Input />}
                name='organizationId'
                disabled={disabled}
            >
                {organizations.map(organization => (
                    <MenuItem key={organization.id} value={organization.id}>
                        {organization.name}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText id='standard-helper-text'>
                {!disabled
                    ? t('choose_organization')
                    : 'Do not need to choose organization'}
            </FormHelperText>
        </FormControl>
    );
};
