import { useEffect } from 'react';

const usePageNavigation = (setCurrentPage, setContentStart, pageNumber, queryLimit, pageCount, navigate, redirectLink) => {
    useEffect(() => {
        setCurrentPage(Math.round(pageNumber));
        setContentStart(queryLimit * (pageNumber - 1));
        if (
            (pageNumber > pageCount ||
                pageNumber < 1 ||
                !pageNumber.match(/^-?\d+$/)) &&
            pageCount !== null
        ) {
            navigate(`/${redirectLink}/${1}`);
        }
    }, [pageNumber,pageCount,queryLimit]);
};

export default usePageNavigation;