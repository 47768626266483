export function validateUser(user, systemRoles, update = false) {
    let correctUserInfo = true;
    const { simpalsLogin, simpalsId, name, accesses } = user;

    if (
        name === '' ||
        simpalsLogin === '' ||
        simpalsId === '' ||
        accesses.length === 0
    ) {
        correctUserInfo = false;
    }
    accesses?.map(access => {
        const isSystemRole = systemRoles.includes(access.role);
        if (
            access.role === '' ||
            (!isSystemRole &&
                (access.organizationId === '' ||
                    access.organizationId === null))
        ) {
            correctUserInfo = false;
        }
    });
    // if (update && user.id === '' && !isNaN(user.organizationId))
    //     correctUserInfo = false;
    // if (isNaN(user.organizationId)) {
    //     correctUserInfo = false;
    // }
    return correctUserInfo;
}

export function validateUserName(user) {
    let correctUserInfo = true;
    const { simpalsId, name } = user;
    if (name === '' || simpalsId === '') {
        correctUserInfo = false;
    }
    return correctUserInfo;
}
