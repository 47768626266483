import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { UserOrganization } from './components/UserOrganization';
import { UserRole } from './components/UserRole';

export const UserOrganizationRole = ({
    index,
    access,
    existingAccess,
    organizations,
    roles,
    setAccess,
    allowRemoving,
    editUser,
    handleAccessManage,
    handleOpenPopup,
}) => {
    const { t } = useTranslation();

    const systemRoles = roles
        .filter(role => role.name.includes('SYSTEM'))
        .map(role => role.name);
    const showSelectOrganization = systemRoles.includes(access.role);

    return (
        <Grid
            container
            item
            spacing={4}
            align='left'
            justifyContent={'left'}
            alignItems='center'
        >
            <Grid item xs={editUser ? 4 : 5}>
                <UserRole
                    key={access.role + index}
                    role={access.role || ''}
                    roles={roles}
                    disabled={existingAccess}
                    setAccess={role => {
                        setAccess(index, role);
                    }}
                />
            </Grid>
            <Grid item xs={editUser ? 4 : 5}>
                <UserOrganization
                    key={access.organizationId + index}
                    organization={access.organizationId || ''}
                    organizations={organizations}
                    disabled={existingAccess || showSelectOrganization}
                    setAccess={organizationId => {
                        setAccess(index, organizationId);
                    }}
                />
            </Grid>
            <Grid
                item
                xs={editUser ? 3 : 1}
                justifyContent='right'
                container
                alignItems='center'
            >
                {editUser && (
                    <Grid item xs={7} align='right'>
                        {!existingAccess && (
                            <Button
                                variant='outlined'
                                onClick={() => handleAccessManage(index)}
                            >
                                {t('save_role')}
                            </Button>
                        )}
                    </Grid>
                )}
                <Grid item xs={editUser ? 3 : 12} align='right'>
                    {allowRemoving && (
                        <IconButton
                            onClick={event => {
                                editUser && existingAccess
                                    ? handleOpenPopup(event, index)
                                    : setAccess(index);
                            }}
                            aria-label='delete'
                            size='small'
                            key={index}
                            aria-describedby='removePopup'
                            type='button'
                        >
                            <ClearIcon fontSize='inherit' />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
