import React from 'react';

import { Routes, Route } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Menu from './admin_components/Menu';
import CompanyList from './admin_components/CompanyList';
import Company from './admin_components/Company';
import CashRegister from './admin_components/CashRegister';
import UserList from './admin_components/UserList';
import User from './admin_components/User';
import NewUser from './admin_components/NewUser';
import EditUser from './admin_components/EditUser';
import RoleReference from './admin_components/RoleReference';

export default function AdminPanel() {
    return (
        <Box
            sx={{
                width: '100%',
                height: '90%',
                position: 'absolute',
                marginTop: 12,
            }}
        >
            <Grid container spacing={3} align='left'>
                <Grid item xs={1} md={1} lg={1}>
                    <Menu />
                </Grid>

                <Grid item xs={11} md={11} lg={11}>
                    <Routes>
                        <Route path='/' element={<CompanyList />} />
                        <Route
                            path='companies/:pageNumber'
                            element={<CompanyList />}
                        />
                        <Route path='company' element={<Company />}>
                            <Route path=':companyId' element={<Company />} />
                        </Route>
                        <Route path='cash-register' element={<CashRegister />}>
                            <Route
                                path=':cashRegisterId'
                                element={<CashRegister />}
                            >
                                <Route
                                    path=':companyId'
                                    element={<CashRegister />}
                                />
                            </Route>
                        </Route>
                        {/* <Route path="new-cash-register" element={<NewCashRegister />} /> */}
                        <Route
                            path='users/:pageNumber'
                            element={<UserList />}
                        />
                        <Route path='user' element={<User />}>
                            <Route path=':userId' element={<User />} />
                            <Route path=':simpalsId' element={<User />} />
                        </Route>
                        <Route path='new-user' element={<NewUser />} />
                        <Route path='edit-user' element={<EditUser />} />
                        <Route
                            path='role-reference'
                            element={<RoleReference />}
                        />
                    </Routes>

                    {/* <NewReceipt /> */}
                </Grid>
            </Grid>
        </Box>
    );
}
