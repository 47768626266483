import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
    getCashById,
    cashRegisterApiKeyRefresh,
} from '../../services/requests/GraphRequests';

export default function CashRegister() {
    let navigate = useNavigate();
    const { t } = useTranslation();
    let params = useParams();
    const [loading, setLoading] = React.useState(true);
    const [companyWithCash, setCompanyWithCash] = React.useState({});
    React.useEffect(() => {
        getCashById(params.companyId, params.cashRegisterId).then(data => {
            setCompanyWithCash(data.data.getOrganization);
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [error, setError] = React.useState({
        status: false,
        msg: 'Error',
    });
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccess(false);
        setError({
            status: false,
            msg: 'Error',
        });
    };

    // https://stackoverflow.com/a/65996386
    // return a promise
    function copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }

    React.useEffect(() => {
        if (openSuccess && loading) {
            setTimeout(function () {
                getCashById(params.cashRegisterId).then(data => {
                    setCompanyWithCash(data.data.getOrganization);
                });
                setLoading(false);
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openSuccess, setOpenSuccess]);

    function refreshApiKey() {
        cashRegisterApiKeyRefresh(companyWithCash.cashRegisters[0].id).then(
            data => {
                if (data.data.cashRegisterApiKeyRefresh !== null) {
                    if (
                        data.data.cashRegisterApiKeyRefresh.errors.length === 0
                    ) {
                        setOpenSuccess(true);
                        setLoading(true);
                    }
                } else {
                    let msgError = 'There are errors while receipt generation';
                    if (data.data.errors.length > 0) {
                        msgError = data.data.errors[0].message;
                    }
                    setError({
                        status: true,
                        msg: msgError,
                    });
                }
            },
        );
    }

    if (loading) {
        return (
            <Container align='center' sx={{ mt: 10 }}>
                <CircularProgress />
            </Container>
        );
    }
    if (!companyWithCash) return null;

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
                <Grid item xs={6}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color='pimary'
                        gutterBottom
                    >
                        {t('cash_machine')} №{' '}
                        {companyWithCash.cashRegisters[0].id}
                    </Typography>
                    <Button
                        variant='text'
                        color='secondary'
                        onClick={() => {
                            navigate(`/company/${companyWithCash.id}`);
                        }}
                    >
                        &#60; {t('go_back')}
                    </Button>
                    {/* Organization info */}
                    <Grid container spacing={2} align='left'>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('org_name')} :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='pimary'
                                gutterBottom
                            >
                                {companyWithCash.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('idno')} :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='pimary'
                                gutterBottom
                            >
                                {companyWithCash.idno}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('contact')} :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='pimary'
                                gutterBottom
                            >
                                {companyWithCash.contact}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('phone_number')} :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='pimary'
                                gutterBottom
                            >
                                {companyWithCash.contactPhone}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('email')} :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='pimary'
                                gutterBottom
                            >
                                {companyWithCash.contactEmail}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} align='right'></Grid>
            </Grid>
            <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
                <Grid item xs={5}>
                    <TextField
                        sx={{
                            m: 1,
                            width: '100%',
                            input: { cursor: 'not-allowed' },
                        }}
                        id='Address'
                        label={t('address')}
                        value={companyWithCash.cashRegisters[0].address}
                        variant='standard'
                        inputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        sx={{
                            m: 1,
                            width: '100%',
                            input: { cursor: 'not-allowed' },
                        }}
                        id='Model'
                        label={t('model')}
                        value={companyWithCash.cashRegisters[0].model}
                        variant='standard'
                        inputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        sx={{
                            m: 1,
                            width: '100%',
                            input: { cursor: 'not-allowed' },
                        }}
                        id='Domain'
                        label={t('domain')}
                        value={companyWithCash.cashRegisters[0].domain}
                        variant='standard'
                        inputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        sx={{
                            m: 1,
                            width: '100%',
                            input: { cursor: 'not-allowed' },
                        }}
                        id='Kind Of Activity'
                        label={t('kind_activity')}
                        value={companyWithCash.cashRegisters[0].kindOfActivity}
                        variant='standard'
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        sx={{
                            m: 1,
                            width: '100%',
                            input: { cursor: 'not-allowed' },
                        }}
                        id='Factory Number'
                        label={t('factory_number')}
                        value={companyWithCash.cashRegisters[0].factory}
                        variant='standard'
                        inputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        sx={{
                            m: 1,
                            width: '100%',
                            input: { cursor: 'not-allowed' },
                        }}
                        id='Registration Number'
                        label={t('registration_number')}
                        value={companyWithCash.cashRegisters[0].number}
                        variant='standard'
                        inputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        sx={{
                            m: 1,
                            width: '100%',
                            input: { cursor: 'not-allowed' },
                        }}
                        id='Key'
                        label={t('key')}
                        value={companyWithCash.cashRegisters[0].key}
                        variant='standard'
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <TextField
                        sx={{
                            m: 1,
                            width: '100%',
                            textarea: { cursor: 'not-allowed' },
                        }}
                        id='ApiKey'
                        label='ApiKey'
                        value={companyWithCash.cashRegisters[0].key}
                        variant='standard'
                        multiline
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>

                <Grid item xs={6} sx={{ marginTop: 2 }}>
                    <Button
                        variant='outlined'
                        sx={{ width: '40%' }}
                        disabled={companyWithCash.cashRegisters[0].key === ''}
                        onClick={() => {
                            copyToClipboard(
                                companyWithCash.cashRegisters[0].key,
                            )
                                .then(() =>
                                    // eslint-disable-next-line no-console
                                    console.log(
                                        'text copied !(TODO: move that to notification)',
                                    ),
                                )
                                .catch(() =>
                                    // eslint-disable-next-line no-console
                                    console.log(
                                        'error(TODO: move that to notification)',
                                    ),
                                );
                        }}
                    >
                        {t('copy_btn')}
                    </Button>
                    <Button
                        variant='outlined'
                        sx={{ width: '40%', marginLeft: 2 }}
                        // disabled={true}
                        onClick={() => {
                            refreshApiKey();
                        }}
                    >
                        {t('generate_btn')}
                    </Button>
                </Grid>
            </Grid>
            <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{ width: '100%' }}
                >
                    Api key refreshed !
                </Alert>
            </Snackbar>
            <Snackbar
                open={error.status}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='error'
                    sx={{ width: '100%' }}
                >
                    {error.msg}
                </Alert>
            </Snackbar>
        </Box>
    );
}
