import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Input from '@mui/material/Input';
import { Pagination } from '@mui/material';
import {
    getOrganizations,
    updateOrganization,
} from '../../services/requests/GraphRequests';
import { queryLimit } from '../../constants';
import usePageNavigation from '../../hooks/usePageNavigation'

export default function CompanyList() {
    let navigate = useNavigate();
    const { pageNumber = '1'} = useParams();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [companyList, setCompanyList] = useState([]);
    const [filteredCompanyList, setFilteredCompanyList] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [organizationsStart, setOrganizationsStart] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageQuantity, setPageQuantity] = useState(null);
    const redirectLink = 'companies'

    useEffect(() => {
        getOrganizations(organizationsStart, queryLimit)
            .then(data => {
                const { data: queryData, count } = data.data.getOrganizations;
                const reversedOrganizations = queryData.reverse();
                const organizationsCount = count || queryData.length;
                setPageQuantity(Math.ceil(organizationsCount / queryLimit));
                setCompanyList(reversedOrganizations);
                setFilteredCompanyList(reversedOrganizations);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                // eslint-disable-next-line no-console
                console.log(error.message ? error.message : error);
            });
    }, [organizationsStart, setOrganizationsStart]);

    usePageNavigation(setCurrentPage,setOrganizationsStart,pageNumber,queryLimit, pageQuantity, navigate,redirectLink)

    const handlePagination = (event, page) => {
        navigate(`/${redirectLink}/${page}`);
    };

    const handleChange = event => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);
        const textInput = inputValue?.toLowerCase();
        const filteredCompanies = companyList.filter(company => {
            return (
                company?.name?.toLowerCase().includes(textInput) ||
                company?.idno?.includes(textInput)
            );
        });
        setFilteredCompanyList(filteredCompanies);
    };

    function updateOrganizationData() {
        updateOrganization().then(data => {
            navigate(0);
        });
    }

    if (loading) {
        return (
            <Container align='center' sx={{ mt: 10 }}>
                <CircularProgress />
            </Container>
        );
    }
    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
                <Grid item xs={3}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color='pimary'
                        gutterBottom
                    >
                        {t('list_companies')}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Input
                        value={searchInput}
                        onChange={handleChange}
                        placeholder={t('search_by_name_idno')}
                        type='string'
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} align='right'>
                    <Button
                        variant='outlined'
                        // sx={{width: '100%'}}
                        // disabled={!vererification || !showReceipt}
                        onClick={() => {
                            updateOrganizationData();
                        }}
                    >
                        {t('organization_sync_btn')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 3 }} align='left'>
                <Grid item xs={11}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('id')}</TableCell>
                                    <TableCell align='right'>
                                        {t('org_name')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('idno')}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {t('cash_registers_amount')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('surname_name')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('phone')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('email')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredCompanyList.map(company => {
                                    return (
                                        <TableRow
                                            hover
                                            key={company.id}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                            onClick={() => {
                                                navigate(
                                                    `/company/${company.id}`,
                                                );
                                            }}
                                        >
                                            <TableCell align='left'>
                                                {company.id}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {company.name}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {company.idno}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {company.cashRegistersCount}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {company.contact}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {company.contactPhone}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {company.contactEmail}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {pageQuantity > 1 && (
                    <Grid item xs={11} align='center'>
                        <Pagination
                            sx={{
                                mt: 5,
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                            count={pageQuantity}
                            variant='outlined'
                            shape='rounded'
                            page={+currentPage}
                            onChange={handlePagination}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}
