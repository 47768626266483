import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Pagination } from '@mui/material';

import { getUsers } from '../../services/requests/GraphRequests';
import { queryLimit } from '../../constants';
import usePageNavigation from '../../hooks/usePageNavigation';

export default function UserList() {
    let navigate = useNavigate();
    const { pageNumber = '1' } = useParams();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [usersStart, setUsersStart] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageQuantity, setPageQuantity] = useState(null);
    const redirectLink = 'users';

    useEffect(() => {
        getUsers(usersStart, queryLimit)
            .then(data => {
                const { data: queryData, count } = data.data.getUsers;
                const usersCount = count || queryData.length;
                setUserList(queryData);
                setPageQuantity(Math.ceil(usersCount / queryLimit));
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                // eslint-disable-next-line no-console
                console.log(error.message ? error.message : error);
            });
    }, [usersStart, setUsersStart]);

    usePageNavigation(
        setCurrentPage,
        setUsersStart,
        pageNumber,
        queryLimit,
        pageQuantity,
        navigate,
        redirectLink,
    );

    const handlePagination = (event, page) => {
        navigate(`/${redirectLink}/${page}`);
    };

    if (loading) {
        return (
            <Container align='center' sx={{ mt: 10 }}>
                <CircularProgress />
            </Container>
        );
    }
    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
                <Grid item xs={6}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color='pimary'
                        gutterBottom
                    >
                        {t('list_users')}
                    </Typography>
                </Grid>
                <Grid item xs={5} align='right'>
                    <Button
                        variant='outlined'
                        // sx={{width: '100%'}}
                        // disabled={!vererification || !showReceipt}
                        onClick={() => {
                            navigate('/new-user');
                        }}
                    >
                        {t('add_user')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 3 }} align='left'>
                <Grid item xs={11}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('id')}</TableCell>
                                    <TableCell>{t('simpals_login')}</TableCell>
                                    <TableCell>{t('roles')}</TableCell>
                                    <TableCell>{t('name')}</TableCell>
                                    <TableCell>{t('organization')}</TableCell>
                                    <TableCell>{t('status')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userList.map(user => {
                                    const {
                                        simpalsId,
                                        simpalsLogin,
                                        userData,
                                    } = user;

                                    return userData.map(data => {
                                        const {
                                            accesses,
                                            id,
                                            name,
                                            organizationData,
                                        } = data;
                                        const accessesAmount = accesses.length;
                                        const userStatus =
                                            accessesAmount >= 1
                                                ? 'Active'
                                                : 'Blocked';

                                        const {
                                            name: organizationName = 'no organization',
                                        } = organizationData;

                                        return (
                                            <TableRow
                                                hover
                                                key={user.id}
                                                sx={{
                                                    cursor: 'pointer',
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                                onClick={() =>
                                                    navigate(
                                                        `/user/${simpalsId}`,
                                                        {
                                                            state: {
                                                                simpalsId:
                                                                    simpalsId,
                                                            },
                                                        },
                                                    )
                                                }
                                            >
                                                <TableCell align='left'>
                                                    {id}
                                                </TableCell>
                                                <TableCell>
                                                    {simpalsLogin}
                                                </TableCell>
                                                <TableCell>
                                                    {accesses?.map(access => {
                                                        const {
                                                            id: accessId = null,
                                                            role: accessRole = '',
                                                        } = access;
                                                        return (
                                                            <div key={accessId}>
                                                                {t(
                                                                    accessRole.toLowerCase(),
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </TableCell>
                                                <TableCell>
                                                    {name ? name : 'NoName'}
                                                </TableCell>
                                                <TableCell>
                                                    {organizationName}
                                                </TableCell>
                                                <TableCell>
                                                    {userStatus}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    });
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                {pageQuantity > 1 && (
                    <Grid item xs={11} align='center'>
                        <Pagination
                            sx={{
                                mt: 5,
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                            count={pageQuantity}
                            variant='outlined'
                            shape='rounded'
                            page={+currentPage}
                            onChange={handlePagination}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}
