import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Pagination } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { queryLimit } from '../../constants';

import { getOrganizationById } from '../../services/requests/GraphRequests';

export default function Company() {
    let navigate = useNavigate();
    let params = useParams();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState({});
    const [cashRegistersCount, setCashRegistersCount] = useState(0);
    const [cashRegistersStart, setCashRegistersStart] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageQuantity = Math.ceil(cashRegistersCount / queryLimit);

    useEffect(() => {
        getOrganizationById(
            params.companyId,
            cashRegistersStart,
            queryLimit,
        ).then(data => {
            let company = data.data.getOrganization;
            company.cashRegisters.reverse();
            setCashRegistersCount(
                company.cashRegistersCount || company.cashRegisters.length,
            );
            setCompany(company);
            setLoading(false);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashRegistersStart, setCashRegistersStart]);   

    const handlePagination = (event, page) => {
        setCurrentPage((prevPage) => {
            if (prevPage !== page) {
                setCashRegistersStart(queryLimit * (page - 1));
            }
            return page;
        });
    };

    if (loading) {
        return (
            <Container align='center' sx={{ mt: 10 }}>
                <CircularProgress />
            </Container>
        );
    }
    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
                <Grid item xs={6}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color='pimary'
                        gutterBottom
                    >
                        {t('organization')} № {params.companyId}
                    </Typography>
                    <Button
                        variant='text'
                        color='secondary'
                        onClick={() => {
                            navigate('/companies');
                        }}
                    >
                        &#60; {t('go_back')}
                    </Button>
                    {/* Organization info */}
                    <Grid container spacing={2} align='left'>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('org_name')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='pimary'
                                gutterBottom
                            >
                                {company.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('idno')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='pimary'
                                gutterBottom
                            >
                                {company.idno}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('surname_name')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='pimary'
                                gutterBottom
                            >
                                {company.contact}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('phone')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='pimary'
                                gutterBottom
                            >
                                {company.contactPhone}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('email')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='pimary'
                                gutterBottom
                            >
                                {company.contactEmail}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} align='right'></Grid>
            </Grid>
            {company.cashRegisters.length > 0 && (
                <>
                    <Grid
                        container
                        sx={{ marginTop: 2 }}
                        spacing={4}
                        align='left'
                    >
                        <Grid item xs={6}>
                            <Typography
                                sx={{ fontSize: 24 }}
                                color='pimary'
                                gutterBottom
                            >
                                {t('cash_machine')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={5} align='right'></Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        sx={{ marginTop: 3 }}
                        align='left'
                    >
                        <Grid item xs={11}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label='simple table'
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('id')}</TableCell>
                                            <TableCell align='right'>
                                                {t('serial_number')}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {t('factory_number')}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {t('model')}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {t('address')}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {t('test')}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {t('domain')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {company.cashRegisters.map(cash => {
                                            return (
                                                <TableRow
                                                    hover
                                                    key={cash.id}
                                                    sx={{
                                                        'td, th': {
                                                            cursor: 'pointer',
                                                        },
                                                        '&:last-child td, &:last-child th':
                                                            { border: 0 },
                                                    }}
                                                    onClick={() => {
                                                        navigate(
                                                            `/cash-register/${cash.id}/${params.companyId}`,
                                                        );
                                                    }}
                                                >
                                                    <TableCell align='left'>
                                                        {cash.id}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {cash.number}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {cash.factory}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {cash.model}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {cash.address}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {cash.domain}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </>
            )}
            {pageQuantity > 1 && (
                <Grid item xs={11} align='center'>
                    <Pagination
                        sx={{
                            mt: 5,
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                        count={pageQuantity}
                        variant='outlined'
                        shape='rounded'
                        page={currentPage}
                        onChange={handlePagination}
                    />
                </Grid>
            )}
        </Box>
    );
}
