import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import { getEnumRoles } from '../../services/requests/GraphRequests';

const RoleReference = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getEnumRoles()
            .then(data => {
                setRoles(data.data.__type.enumValues);
                setLoading(false);
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Container align='center' sx={{ mt: 10 }}>
                <CircularProgress />
            </Container>
        );
    }
    if (!roles?.length) return null;
    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid
                container
                sx={{ marginTop: 2 }}
                spacing={4}
                align='left'
                xs={11}
            >
                <Grid item xs={11}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color='pimary'
                        gutterBottom
                    >
                        {t('role_reference')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: 2 }} align='left'>
                {roles.map((userRole, index) => {
                    return (
                        <Grid
                            container
                            xs={11}
                            sx={{ marginTop: 2 }}
                            spacing={1}
                            alignItems='center'
                            key={userRole.name + index}
                        >
                            <Grid item xs={3}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color='pimary'
                                    gutterBottom
                                >
                                    {t(userRole.name.toLowerCase())}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color='text.secondary'
                                    gutterBottom
                                >
                                    {t(
                                        `user_role_${userRole.name.toLowerCase()}`,
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default RoleReference;
