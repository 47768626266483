import axios from 'axios';
import Cookies from 'js-cookie';
import { devMevAuth } from '../../constants';

const basePath =
    process.env.NODE_ENV === 'development'
        ? 'https://admin.ekassa.id.md/data'
        : '/data';

axios.defaults.withCredentials = true;

if (process.env.NODE_ENV === 'development') {
    axios.defaults.headers.common['auth'] = Cookies.get('simpalsid.auth');
    if (Cookies.get('simpalsid.auth') === devMevAuth) {
        axios.defaults.headers.common['access_id'] = 12;
    }
}

// required in case of opening a project where the backend will be run locally
// const basePath = process.env.NODE_ENV === "development" ? "http://0.0.0.0:9031/data" : "/data";

// POST request to GraphQL get all companyes
export function getOrganizations(start, limit) {
    const organization = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
       query{
        getOrganizations(start: ${start}, limit: ${limit}){
          data{
            id, 
            name, 
            idno, 
            contact, 
            contactPhone, 
            contactEmail, 
            cashRegistersCount,
            organizationPhone,
            organizationEmail,
          }
          count
        }
      }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return organization;
}

// POST request to GraphQL get companyes by id
export function getOrganizationById(organizationId, crStart, crLimit) {
    const organization = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
         query{
            getOrganization(id: ${organizationId},crStart: ${crStart},crLimit: ${crLimit}){
              id,
              name,
              idno,
              contact,
              contactEmail,
              contactPhone,
              organizationPhone,
              organizationEmail,
              contract,
              watPay,
              cashRegistersCount,
              cashRegisters{
                id, model, address, kindOfActivity, factory, number,key,domain}
              }
          }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return organization;
}

// POST request to GraphQL create new organization
//TODO: fix wat pay
export function createNewOrganization(organization) {
    return axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
         mutation{
          organizationCreate(
            input: {
              name: "${organization.name}",
              contact: "${organization.contact}",
              phone: "${organization.phone}",
              email: "${organization.email}",
              contract: "${organization.contract}",
              idno: "${organization.idno}",
              watPay: ${organization.watPay ? 1 : 0}
            }
          ){errors {message}}
        }`,
        },
        // config: config,
    })
        .then(res => {
            return res;
        })
        .catch(err => {
            return err.message;
        });
}

// POST request to GraphQL update data of organization from MEV admin
export function updateOrganization() {
    return axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
        mutation{
          organizationDataRefresh{
            errors {
              message
            }
          }
        }`,
        },
        // config: config,
    })
        .then(res => {
            return res;
        })
        .catch(err => {
            return err.message;
        });
}

export function getEnumRoles() {
    const roles = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `{   
        __type(name: "Role") {
          enumValues {
            name
            description
          }
        }
      }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return roles;
}

// POST request to GraphQL create new CashRegister
export function createNewCashRegister(cashRegister, organizationId) {
    return axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
       mutation{
        cashRegisterCreate(
          input: {
            model: "${cashRegister.model}"
            address: "${cashRegister.address}"
            kindOfActivity: "${cashRegister.kindOfActivity}"
            factory: "${cashRegister.factory}"
            number: "${cashRegister.number}"
            key: "${cashRegister.key}"
            domain: "${cashRegister.domain}"
          },
          organizationId: ${organizationId}
        ){errors {message}}
      }`,
        },
        // config: config,
    })
        .then(res => {
            return res;
        })
        .catch(err => {
            return err.message;
        });
}

// POST request to GraphQL update CashRegister
export function cashRegisterUpdate(cashRegister) {
    return axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
       mutation{
        cashRegisterUpdate(
          input: {
            id: ${cashRegister.id}
            model: "${cashRegister.model}"
            address: "${cashRegister.address}"
            kindOfActivity: "${cashRegister.kindOfActivity}"
            factory: "${cashRegister.factory}"
            number: "${cashRegister.number}"
            key: "${cashRegister.key}"
            domain: "${cashRegister.domain}"
          }
        ){errors {message}}
      }`,
        },
        // config: config,
    })
        .then(res => {
            return res;
        })
        .catch(err => {
            return err.message;
        });
}

// POST request to GraphQL cash Register Api Key Refresh
export function cashRegisterApiKeyRefresh(cashId) {
    const organization = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
       mutation{
        cashRegisterApiKeyRefresh(
          id: ${cashId},
        ){errors {message}}
      }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return organization;
}

// POST request to GraphQL get cash register by id
export function getCashById(orgId, cashId) {
    const organization = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
         query{
            getOrganization(id: ${orgId}, cashRegisterId: ${cashId}){
              id,
              name,
              idno,
              contact,
              contactEmail,
              contactPhone,
              organizationPhone,
              organizationEmail,
              cashRegisters{
                id, model, address, kindOfActivity, factory, number, key,domain}
              }
          }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return organization;
}

export function simpalsUserAutocomplete(userLogin) {
    const organization = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
        query{
          simpalsUserAutocomplete(filter: "${userLogin}"){
            userId,
            login
          }
        }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return organization;
}

// POST request to GraphQL get all users
export function getUsers(start, limit) {
    const organization = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
            query{
              getUsers(start: ${start}, limit: ${limit}){
                count
                data {
                  simpalsId
                  simpalsLogin
                  userData {
                    id
                    organizationData {
                      id
                      name
                    }
                    name
                    blocked
                    deleted
                    cashRegisters {
                      cashRegisterId
                      apiKey
                      cashRegister {
                      id
                      model
                      address
                      kindOfActivity
                      factory
                      number
                      key
                      domain
                      taxes {
                        id
                        title
                        percent
                        code
                        rate
                        enabled
                      }
                      type
                    }
                  }
                  accesses {
                    id
                    organizationId
                    organization {
                      id
                      name
                      contact
                      contactPhone
                      contactEmail
                      organizationPhone
                      organizationEmail
                      contract
                      idno
                      cashRegisters {
                        id
                        model
                        address
                        kindOfActivity
                        factory
                        number
                        key
                        domain
                        type
                      }
                      cashRegistersCount
                      watPay
                    }
                    role
                  }
                }
              }
            }
          }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return organization;
}

// POST request to GraphQL get all users
export function getUserById(simpalsId) {
    const organization = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
       query{
        getUser(simpalsId: "${simpalsId}"){
          id, 
          simpalsLogin, 
          simpalsId, 
          name,  
          organizationId,
          blocked,
          deleted
          currentAccess{
            id,
            organizationId,
            role
          }, 
          accesses {
            id, 
            organizationId,
            role,
            organization {
                id
                name
            }
          },
          cashRegisters{
            cashRegisterId,
            apiKey,
          },
        }
      }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return organization;
}

// POST request to GraphQL create user
export function createUser(user) {
    const newUser = axios
        .post(basePath, {
            query: `
            mutation createUser($input: AdminUserCreateInput!) {
              userCreate(input: $input) {
                errors {
                  message
                }
              }
            }
          `,
            variables: {
                input: {
                    name: user.name,
                    simpalsId: user.simpalsId,
                    accesses: user.accesses,
                },
            },
        })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return newUser;
}

// POST request to GraphQL update user
export function updateUser(user) {
    const organization = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
         mutation{
          userUpdate(
            input: {
              name: "${user.name}",
              simpalsId: "${user.simpalsId}",
            },
          ){errors {message}}
        }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return organization;
}

// POST request to GraphQL update user's role
export function userAccessManage(user) {
    const organization = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
       mutation{
        userAccessManage(
            create: ${user.create},
            organizationId: ${user.organizationId},
            role: ${user.role},
            simpalsId: "${user.simpalsId}",
        ){
            errors {
                message
            }
            user{
                id, 
                simpalsLogin, 
                simpalsId, 
                name,  
                organizationId,
                currentAccess{
                  id,
                  organizationId,
                  role
                }, 
                accesses {
                  id, 
                  organizationId,
                  role,
                },
                cashRegisters{
                  cashRegisterId,
                  apiKey,
                }
            }
        }
      }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return organization;
}

// POST request to GraphQL block/unblock user
export function blockUser(id, status) {
    const blockedUser = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
            mutation{
                userBlock(
                    id: ${id},
                    block: ${status}
                ){errors {message}}
            }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return blockedUser;
}

// POST request to GraphQL block/unblock user
export function deleteUser(id, status) {
    const userDelete = axios({
        url: basePath,
        method: 'post',
        data: {
            query: `
            mutation{
                userDelete(
                    id: ${id},
                ){errors {message}}
            }`,
        },
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err.message;
        });
    return userDelete;
}
