import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useParams } from 'react-router-dom';

export default function Menu() {
    let navigate = useNavigate();
    const { '*': params } = useParams();
    const { t } = useTranslation();
    const [colorsBG, setColorsBG] = React.useState({
        companies: '#6b6d78',
        // cashRegisters: '#313238',
        users: '#313238',
        role_reference: '##313238',
    });

    const setBGcolor = target => {
        let newColors = {};
        Object.keys(colorsBG).map(key => {
            if (key === target) {
                newColors[key] = '#6b6d78';
            } else {
                newColors[key] = '#313238';
            }
        });
        setColorsBG(newColors);
    };
    const handleClick = (bgColor, navigatePath) => {
        setBGcolor(bgColor);
        navigate(navigatePath);
    };

    useEffect(() => {
        switch (true) {
            case params?.includes('users'):
                setBGcolor('users');
                break;
            case params?.includes('companies'):
                setBGcolor('companies');
                break;
            case params?.includes('role-reference'):
                setBGcolor('role_reference');
                break;
            default:
        }
    }, [params]);

    return (
        <Box
            sx={{
                backgroundColor: '#313238',
                height: '99%',
                position: 'fixed',
            }}
        >
            <MenuList sx={{ marginTop: 2 }}>
                <MenuItem
                    key={t('organization')}
                    sx={{ backgroundColor: colorsBG.companies }}
                >
                    <ListItemText
                        sx={{ color: '#FFFFFF' }}
                        onClick={() => handleClick('companies', '/companies/1')}
                    >
                        {t('organization')}
                    </ListItemText>
                </MenuItem>

                {/* <MenuItem
        sx={{backgroundColor: colorsBG.cashRegisters}}
        >
          <ListItemText sx={{color: '#FFFFFF'}} 
          onClick={() => { setBGcolor('cashRegisters'); navigate("/cash-registers");}}>Cash registers</ListItemText>
        </MenuItem> */}

                <MenuItem
                    key={t('users')}
                    sx={{ backgroundColor: colorsBG.users }}
                >
                    <ListItemText
                        sx={{ color: '#FFFFFF' }}
                        onClick={() => handleClick('users', '/users/1')}
                    >
                        {t('users')}
                    </ListItemText>
                </MenuItem>

                <MenuItem
                    key={t('role_reference')}
                    sx={{ backgroundColor: colorsBG.role_reference }}
                >
                    <ListItemText
                        sx={{ color: '#FFFFFF' }}
                        onClick={() =>
                            handleClick('role_reference', '/role-reference')
                        }
                    >
                        {t('role_reference')}
                    </ListItemText>
                </MenuItem>
            </MenuList>
        </Box>
    );
}
