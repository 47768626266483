import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Typography,
    Grid,
    Button,
    Alert,
    Snackbar,
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
    Autocomplete,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    // Select,
    // MenuItem,
} from '@mui/material';
import { Popper } from '@mui/base/Popper';
import { UserOrganizationRole } from './userFormControl/UserOrganizationRole';
import { useUserFields } from './hooks/useUserFields';

export const UserFields = ({ pageTitle, userInfo }) => {
    const { t } = useTranslation();

    const {
        user,
        roles,
        logins,
        organizations,
        existingAccess,
        deletionIndex,
        open,
        blockUserOpen,
        anchorEl,
        action,
        setAnchorEl,
        idPopup,
        readyToSave,
        openSuccess,
        error,
        alertText,
        setAction,
        handleClose,
        handleOpenPopup,
        setBlockUserOpen,
        handleChangeUser,
        handleLoginInput,
        handleChangeAccess,
        addNewOrganizationRole,
        handleAccessManage,
        saveUser,
        editUser,
        blockStatusUser,
        removeUser,
    } = useUserFields(userInfo);

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
                <Grid item xs={6}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color='pimary'
                        gutterBottom
                    >
                        {t(pageTitle)}
                    </Typography>
                </Grid>
                {!userInfo && (
                    <Grid item xs={5} align='right'>
                        <Button
                            variant='outlined'
                            disabled={!readyToSave}
                            onClick={() => saveUser()}
                        >
                            {t('save_user_btn')}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12}>

                {userInfo && (
                    <Grid container sx={{ marginTop: 2 }} align='left'>
                        <Grid item xs={11} align='right'>
                            <Button
                                variant='outlined'
                                disabled={!readyToSave}
                                onClick={() => {
                                    setBlockUserOpen(true);
                                    setAction(userInfo.blocked ? 'block' : 'unblock');
                                }}
                            >
                                {userInfo?.blocked ? t('unblock_user_btn') : t('block_user_btn')}
                            </Button>
                            <Button
                                variant='outlined'
                                disabled={!readyToSave}
                                onClick={() => {
                                    setBlockUserOpen(true);
                                    setAction('delete');
                                }}
                                sx={{marginLeft: 2}}
                            >
                                {t('delete_user_btn')}
                            </Button>
                        </Grid>
                    </Grid>
                )}
                <Grid
                    container
                    item
                    sx={{ marginTop: 2 }}
                    spacing={4}
                    align='left'
                >
                    <Grid item xs={userInfo ? 4 : 5}>
                        <FormControl fullWidth sx={{ m: 1 }} variant='standard'>
                            <Autocomplete
                                id='component-autocomplete'
                                options={logins}
                                getOptionLabel={login => {
                                    return login &&
                                        Object.prototype.hasOwnProperty.call(
                                            login,
                                            'login',
                                        )
                                        ? // login.hasOwnProperty('login')
                                          login.login
                                        : login;
                                }}
                                freeSolo
                                value={user.simpalsLogin}
                                disabled={!!userInfo}
                                onChange={(event, newValue) => {
                                    handleChangeUser({
                                        simpalsLogin: newValue?.login || '',
                                        simpalsId: newValue?.userId || '',
                                    });
                                }}
                                onInputChange={handleLoginInput}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            {...params}
                                            label={t('simpals_login')}
                                            variant='standard'
                                            value={user.simpalsLogin}
                                        />
                                    );
                                }}
                            />
                            <FormHelperText id='standard-helper-text'>
                                {t('must_not_empty')}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={userInfo ? 4 : 5}>
                        <FormControl fullWidth sx={{ m: 1 }} variant='standard'>
                            <InputLabel htmlFor='component-simple'>
                                {t('surname_first_name')}
                            </InputLabel>
                            <Input
                                id='component-simple'
                                value={user.name}
                                onChange={event => {
                                    handleChangeUser({
                                        name: event.target.value,
                                    });
                                }}
                                name='name'
                                aria-describedby='standard-helper-text'
                                inputProps={{
                                    'aria-label': 'text',
                                }}
                            />
                            <FormHelperText id='standard-helper-text'>
                                {t('must_not_empty')}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    {userInfo && (
                        <Grid
                            item
                            xs={3}
                            justifyContent='left'
                            container
                            alignItems='center'
                        >
                            <Grid item xs={12} align='right'>
                                <Button
                                    variant='outlined'
                                    disabled={
                                        !readyToSave ||
                                        userInfo.name.trim() ===
                                            user.name.trim()
                                    }
                                    onClick={() => editUser()}
                                >
                                    {t('save_name')}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {user.accesses.map((access, index) => {
                    return (
                        <UserOrganizationRole
                            key={index}
                            index={index}
                            access={access}
                            organizations={organizations}
                            roles={roles}
                            setAccess={handleChangeAccess}
                            allowRemoving={user.accesses.length > 1}
                            editUser={!!userInfo}
                            existingAccess={
                                existingAccess?.includes(access.id) || false
                            }
                            handleAccessManage={handleAccessManage}
                            handleOpenPopup={handleOpenPopup}
                        />
                    );
                })}
                <Grid container item sx={{ marginTop: 2 }} align='left'>
                    <Button variant='outlined' onClick={addNewOrganizationRole}>
                        {t('add_new_organization')}
                    </Button>
                </Grid>
                <Grid container item>
                    <Popper id={idPopup} open={open} anchorEl={anchorEl}>
                        <Box
                            component='div'
                            sx={{
                                display: 'block',
                                backgroundColor: 'white',
                                boxShadow: '0px 4px 8px rgb(0 0 0 / 0.1)',
                                borderRadius: '8px',
                                padding: '20px',
                                border: '1px solid rgb(0 0 0 / 0.2)',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                variant='body1'
                                component='div'
                                sx={{ marginBottom: 1 }}
                            >
                                {t('are_you_sure')}
                            </Typography>
                            <Button
                                variant='outlined'
                                sx={{ marginRight: 1 }}
                                onClick={() =>
                                    handleAccessManage(deletionIndex, false)
                                }
                            >
                                {t('yes')}
                            </Button>
                            <Button
                                variant='outlined'
                                onClick={() => setAnchorEl(null)}
                            >
                                {t('no')}
                            </Button>
                        </Box>
                    </Popper>
                </Grid>
                {/* <Grid container item sx={{ marginTop: 2 }} align='left'>
                    <FormControl fullWidth sx={{ m: 1 }} variant='standard'>
                        <InputLabel htmlFor='component-simple'>
                            {t('simpals_id')}
                        </InputLabel>
                        <Input
                            id='component-simple'
                            value={user.simpalsId}
                            name='simpalsId'
                            onChange={handleChange}
                            aria-describedby='standard-helper-text'
                            inputProps={{
                                'aria-label': 'text',
                            }}
                        />
                        <FormHelperText id='standard-helper-text'>
                            {t('must_not_empty')}
                        </FormHelperText>
                    </FormControl>
                </Grid> */}
            </Grid>
            <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{ width: '100%' }}
                >
                    {alertText}
                </Alert>
            </Snackbar>
            <Snackbar
                open={error.status}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='error'
                    sx={{ width: '100%' }}
                >
                    {error.msg}
                </Alert>
            </Snackbar>
            <Dialog
                open={blockUserOpen}
                onClose={() => setBlockUserOpen(false)}
                aria-labelledby="send-dialog-title"
                aria-describedby="send-dialog-description"
                maxWidth="xs"
            >
                <DialogTitle id="send-dialog-title" align="center" variant="h6">
                    { action === 'delete' ? t('delete_user_confirm') 
                        : (action === 'block') ? t('block_user_confirm')
                            : t('unblock_user_confirm')}
                </DialogTitle>
                <DialogContent align="center">
                    {/* <FormControl
                        fullWidth
                        sx={{ m: 1 }}
                        variant='standard'
                    >
                        <Select
                            id='component-simple'
                            labelId='demo-simple-select-helper-label'
                            label={t('organization')}
                            // value={organization}
                            onChange={(e) => handleSelectOrganization(e.target.value)}
                            input={<Input />}
                            name='organizationId'
                            required
                            // disabled={disabled}
                        >
                            {organizations.map(organization => (
                                <MenuItem key={organization.id} value={organization.id}>
                                    {organization.name}
                                </MenuItem>
                            ))}
                            
                        </Select>
                        <FormHelperText id='standard-helper-text'>
                            {t('choose_organization')}
                        </FormHelperText>
                    </FormControl> */}
                    <Button
                        variant='outlined'
                        sx={{
                            marginTop: 2,
                            color: '#adadad',
                            borderColor: '#adadad',

                        }}
                        onClick={() => setBlockUserOpen(false)}
                    >
                        {t('no')}
                    </Button>
                    <Button
                        variant='outlined'
                        sx={{
                            marginTop: 2,
                            marginLeft: 2
                        }}
                        onClick={() => {
                            action === 'delete' ? removeUser(userInfo.id) : blockStatusUser(userInfo.id, !userInfo.blocked)
                        }}
                    >
                        {t('yes')}
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
