import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Logo from '../assets/999eKassa.png';

export default function TopBar() {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [auth, setAuth] = React.useState(false);

    React.useEffect(() => {
        if (Cookies.get('simpalsid.auth')) {
            setAuth(true);
        } else {
            setAuth(false);
        }
    }, []);

    return (
        <Box sx={{ flexGrow: 1, position: 'absolute', width: '100%' }}>
            <AppBar
                // position="absolute"
                sx={{
                    marginTop: '35px',
                    backgroundColor: '#313238',
                    height: '76px',
                }}
            >
                <Toolbar>
                    <IconButton
                        size='large'
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        sx={{
                            mr: 2,
                            marginRight: '30px',
                            height: '76px',
                            width: '162px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={Logo}
                            sx={{ height: '36px', top: '50%' }}
                            alt='logo'
                            onClick={() => {
                                navigate('/');
                            }}
                        />
                    </IconButton>
                    <Typography
                        variant='h6'
                        component='div'
                        sx={{ flexGrow: 1 }}
                    ></Typography>

                    <Button
                        variant='outlined'
                        sx={{
                            color: '#acb44e',
                            borderColor: '#acb44e',
                            visibility: auth ? 'visible' : 'hidden',
                        }}
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        <b>{t('admin_panel')}</b>
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
