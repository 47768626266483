import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    validateUser,
    validateUserName,
} from '../../../../services/Validations';
import {
    simpalsUserAutocomplete,
    getOrganizations,
    getEnumRoles,
    userAccessManage,
} from '../../../../services/requests/GraphRequests';
import {
    createUser,
    updateUser,
    blockUser,
    deleteUser,
} from '../../../../services/requests/GraphRequests';

export const useUserFields = userInfo => {
    const navigate = useNavigate();
    const [logins, setLogins] = useState([]);
    const [roles, setRoles] = useState([]);
    const [existingAccess, setExistingAccesses] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [readyToSave, setReadyToSave] = useState(true);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [blockUserOpen, setBlockUserOpen] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [deletionIndex, setDeletionIndex] = useState(null);
    const [organizationId, setOrganizationId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const idPopup = open ? 'removePopup' : undefined;
    const [action, setAction] = useState('');

    const handleOpenPopup = (event, index) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setDeletionIndex(index);
    };

    const [error, setError] = useState({
        status: false,
        msg: 'Error',
    });
    const [user, setUser] = useState({
        name: '',
        simpalsId: '',
        simpalsLogin: '',
        accesses: [{ role: '', organizationId: null }],
    });
    const systemRoles = roles
        .filter(role => role.name.includes('SYSTEM'))
        .map(role => role.name);

    const { simpalsLogin, simpalsId, name, accesses } = userInfo || {
        simpalsLogin: '',
        simpalsId: '',
        name: '',
        accesses: [],
        organizationId: null,
        id: '',
        currentAccess: '',
    };

    useEffect(() => {
        if (userInfo) {
            setReadyToSave(validateUserName(user));
        } else {
            setReadyToSave(validateUser(user, systemRoles));
        }
    }, [user, setUser]);

    useEffect(() => {
        if (userInfo) {
            setUser({
                name: name,
                simpalsId: simpalsId,
                simpalsLogin: simpalsLogin,
                accesses: accesses,
            });
            const accessesIds = accesses.map(access => access.id);
            setExistingAccesses(accessesIds);
            // const userOrganizationIds = accesses.filter(access => access.organizationId);
            // setUserOrganizations()
        }
        getEnumRoles()
            .then(data => {
                setRoles(data.data.__type.enumValues);
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
        getOrganizations(0, 0)
            .then(data => {
                const allOrganizations = data.data.getOrganizations.data;
                setOrganizations(allOrganizations.reverse());
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }, []);

    // useEffect(() => {
    //     if (openSuccess) {
    //         setTimeout(() => {
    //             // navigate(`/users`);
    //             window.location.reload();
    //         }, 1000);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [openSuccess, setOpenSuccess]);

    const handleChangeUser = userField => {
        setUser({
            ...user,
            ...userField,
        });
    };

    const handleSelectOrganization = (id) => {
        setOrganizationId(id);
    }

    const handleLoginInput = event => {
        const value = event?.target?.value || '';
        if (value) {
            if (value) {
                simpalsUserAutocomplete(value)
                    .then(data => {
                        const loginsData = data.data.simpalsUserAutocomplete;
                        setLogins(loginsData ? loginsData : []);
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.log(error.message);
                    });
            } else {
                setLogins([]);
            }
        }
    };

    const handleChangeAccess = (accessIndex, newUserAccess = '') => {
        let newAccesses = [];
        if (newUserAccess) {
            newAccesses = user.accesses.map((userAccess, index) => {
                if (index === accessIndex) {
                    const filteredUserAccess =
                        newUserAccess?.role &&
                        systemRoles.includes(newUserAccess.role)
                            ? { ...userAccess, organizationId: null }
                            : { ...userAccess };

                    return { ...filteredUserAccess, ...newUserAccess };
                } else {
                    return userAccess;
                }
            });
        } else {
            newAccesses = user.accesses.filter((userAccess, index) => {
                return index !== accessIndex;
            });
        }
        setUser({
            ...user,
            accesses: newAccesses,
        });
    };

    const addNewOrganizationRole = () => {
        setUser({
            ...user,
            accesses: [...user.accesses, { organizationId: null, role: '' }],
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccess(false);
        setError({
            status: false,
            msg: 'Error',
        });
    };

    const handleAccessManage = (accessIndex, accessCreate = true) => {
        let userNewAccess = {};
        if (!accessCreate) setAnchorEl(null);
        user.accesses.forEach((userAccess, index) => {
            if (index === accessIndex) {
                userNewAccess = {
                    create: accessCreate,
                    organizationId: userAccess.organizationId,
                    role: userAccess.role,
                    simpalsId: user.simpalsId,
                };
            }
        });
        userAccessManage(userNewAccess)
            .then(data => {
                const { user, errors } = data.data.userAccessManage;
                if (user && errors?.length === 0) {
                    const newAccessesIds = user.accesses.map(
                        access => access.id,
                    );
                    setExistingAccesses(newAccessesIds);
                    const newAccesses = user.accesses;
                    setUser({ ...user, accesses: newAccesses });
                } else {
                    let msgError = 'There are errors while managing roles';
                    if (errors?.length > 0) {
                        msgError = errors[0].message;
                    }
                    setError({
                        status: true,
                        msg: msgError,
                    });
                }
            })
            .catch(error => {
                setError({
                    status: true,
                    msg: error?.message || 'error',
                });
            });
    };

    const saveUser = () => {
        delete user.simpalsLogin;
        createUser(user)
            .then(data => {
                if (data?.data?.userCreate !== null) {
                    if (data.data.userCreate.errors.length === 0) {
                        setOpenSuccess(true);
                        setAlertText('User created!');
                        setTimeout(() => {
                            navigate('/users')
                        }, 500);
                    }
                } else {
                    let msgError = 'There are errors while user created';
                    if (data?.data?.errors?.length > 0) {
                        msgError = data.data.errors[0].message;
                    }
                    setError({
                        status: true,
                        msg: msgError,
                    });
                }
            })
            .catch(error => {
                setError({
                    status: true,
                    msg: error?.message || 'error',
                });
            });
    };

    const editUser = () => {
        updateUser(user)
            .then(data => {
                if (data?.data?.userUpdate !== null) {
                    if (data.data.userUpdate.errors.length === 0) {
                        setOpenSuccess(true);
                        setAlertText('User name edited!');
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                } else {
                    let msgError = 'There are errors while user updated';
                    if (data.data.errors.length > 0) {
                        msgError = data.data.errors[0].message;
                    }
                    setError({
                        status: true,
                        msg: msgError,
                    });
                }
            })
            .catch(error => {
                setError({
                    status: true,
                    msg: error?.message || 'error',
                });
            });
    };

    const blockStatusUser = (id, status) => {
        blockUser(id, status)
            .then(data => {
                if (data?.data?.userBlock !== null) {
                    if (data.data.userBlock.errors.length === 0) {
                        setOpenSuccess(true);
                        setAlertText(status ? 'User blocked!' : 'User Unblocked');
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                } else {
                    let msgError = 'There are errors while user block';
                    if (data.data.errors.length > 0) {
                        msgError = data.data.errors[0].message;
                    }
                    setError({
                        status: true,
                        msg: msgError,
                    });
                }
            })
            .catch(error => {
                setError({
                    status: true,
                    msg: error?.message || 'error',
                });
            });
    };

    const removeUser = (id) => {
        deleteUser(id)
            .then(data => {
                if (data?.data?.userBlock !== null) {
                    if (data.data.userDelete.errors.length === 0) {
                        setOpenSuccess(true);
                        setAlertText('User deleted!');
                        setTimeout(() => {
                            navigate(`/users`);
                        }, 1000);
                    }
                } else {
                    let msgError = 'There are errors while user delete';
                    if (data.data.errors.length > 0) {
                        msgError = data.data.errors[0].message;
                    }
                    setError({
                        status: true,
                        msg: msgError,
                    });
                }
            })
            .catch(error => {
                setError({
                    status: true,
                    msg: error?.message || 'error',
                });
            });
    };

    return {
        user,
        roles,
        logins,
        organizations,
        existingAccess,
        deletionIndex,
        organizationId,
        action,
        open,
        anchorEl,
        setAnchorEl,
        idPopup,
        readyToSave,
        openSuccess,
        blockUserOpen,
        error,
        alertText,
        setAction,
        handleClose,
        handleOpenPopup,
        setBlockUserOpen,
        handleChangeUser,
        handleLoginInput,
        handleChangeAccess,
        addNewOrganizationRole,
        handleAccessManage,
        handleSelectOrganization,
        saveUser,
        editUser,
        blockStatusUser,
        removeUser
    };
};
