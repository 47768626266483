import React, { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

import { useLocation } from 'react-router-dom';

import { getUserById } from '../../services/requests/GraphRequests';
// import UserProfilerCard from './user_components/UserProfilerCard';
import { UserFields } from './user_components/UserFields';

export default function User() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});

    useEffect(() => {
        getUserById(location.state.simpalsId).then(data => {
            setUser(data.data.getUser);
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <Container align='center' sx={{ mt: 10 }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!user) return null;

    return (
        <Box sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}>
            <UserFields pageTitle='edit_user' userInfo={user} />;
        </Box>
    );
}
