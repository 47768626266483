import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
    Select,
    MenuItem,
} from '@mui/material';

export const UserRole = ({ role, roles, disabled, setAccess }) => {
    const { t } = useTranslation();

    const handleSelectRole = event => {
        setAccess({ role: event.target.value });
    };

    if (!roles.length) return null;

    return (
        <FormControl fullWidth sx={{ m: 1 }} variant='standard'>
            <InputLabel htmlFor='component-simple'>{t('role')}</InputLabel>
            <Select
                id='component-simple'
                labelId='demo-simple-select-helper-label'
                label={t('role')}
                value={role}
                onChange={handleSelectRole}
                input={<Input />}
                name='role'
                disabled={disabled}
            >
                {roles.map((userRole, userIndex) => (
                    <MenuItem
                        key={userRole.name + userIndex}
                        value={userRole.name}
                    >
                        {t(userRole.name.toLowerCase())}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText id='standard-helper-text'>
                {t('select_role')}
            </FormHelperText>
        </FormControl>
    );
};
