import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import i18n from '../src/i18n';

import './App.css';
import TopBar from './components/TopBar';
import AdminPanel from './components/AdminPanel';

function App() {
    return (
        <div className='App'>
            <I18nextProvider i18n={i18n}>
                <Router>
                    <div>
                        <TopBar />
                        {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
                        <Routes>
                            <Route path='/*' element={<AdminPanel />} />
                        </Routes>
                    </div>
                </Router>
            </I18nextProvider>
        </div>
    );
}

export default App;
