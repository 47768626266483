import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';
import roTranslations from './translations/ro';
import ruTranslations from './translations/ru';
import enTranslations from './translations/en';

const resources = {
  ru: {
    translation: ruTranslations,
  },
  ro: {
    translation: roTranslations,
  },
  en: {
    translation: enTranslations,
  },
};

i18n
  .use(initReactI18next)
  .use({
    type: 'languageDetector',
    async: true,
    init: () => {},
    detect: (func) => {
      try {
        if (Cookies.get('simpalsid.lang')) {
          return func(Cookies.get('simpalsid.lang'));
        } else {
          return func('ru');
        }
      } catch (error) {
        console.warn(error);
        return func('ru');
      }
    },
  })
  .init({
    resources,
    supportedLngs: ["ro", "ru", "en"],
    react: {
      useSuspense: false,
    },
  }, (err, _) => {
    if (err) return console.error(err)
  });

export default i18n;
